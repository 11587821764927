import React, { useContext, useEffect, useState } from 'react';
import Sidenav from '../components/sidenav';
import ThemeSwitch from '../components/global/ThemeSwitch';
import { useHistory,useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import BottomNav from '../components/global/BottomNav';
import ChangeTheme from '../components/global/ChangeTheme';
import { ThemeContext } from '../context/ThemeStore';
import UserAuthProfile from '../components/global/UserAuthProfile';

const Dashboard = ({ children }) => {
  const [cookies] = useCookies();
  const router = useHistory();
  const location = useLocation();
  const { user } = useContext(ThemeContext);

  const isLms = sessionStorage.getItem("lms");

  const isSolutionsPage = () => {
    const solutionsPattern = /^\/[\w-]+\/[\w-]+\/solutions$/;
    return solutionsPattern.test(location.pathname);
  };

  useEffect(() => {
    if (!Cookies.get('token') && !sessionStorage.getItem("token")) {
      router.push(`/auth?redirect_url=${location.pathname + location.search}`);
    }
    const user = JSON.parse(localStorage.getItem('userProfile'));
    if (user && !user.isOnboardingDone && !isLms) router.push(`/onboarding?step=1`);
  }, []);

  return (
    <div>
      <UserAuthProfile />
      {!isLms && <Sidenav />}
      <ChangeTheme isLms={isLms} />
      <div className="main-content mb-5 mb-sm-0 pb-4 pb-sm-0">
        {children}
      </div>
      {!isLms && !isSolutionsPage() && <BottomNav />}
    </div>
  );
};

export default Dashboard;
