import React, { useState, useEffect } from "react";

import Latex from "../../components/global/Latex";
import { Modal } from "react-bootstrap";
import Fancybox from "../global/FancyBox";
import VideoModal from "../global/Modal";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const QUESTION_TYPE = {
  NUMERICAL: "numerical",
  MULTIPLE_CORRECT: "multipleCorrect",
  SINGLE_CORRECT: "singleCorrect",
  FILL_THE_BLANKS: "fillTheBlanks"
};

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

const Question = ({
                    question,
                    index = 1,
                    viewingFrom,
                    hideAnswerFromErrors
                  }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [attemptStatus, setAttemptStatus] = useState(null);
  const [numericalValue, setNumericalValue] = useState(null);
  const [showHideAnswerModal, setShowHideAnswerModal] = useState(false);
  const [showHideAnswerText, setShowHideAnswerText] = useState(true);
  const [otp, setOtp] = useState("");
  const [playbackInfo, setPlaybackInfo] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    setAttemptStatus(null);
    setSelectedOption(null);
    setSelectedOptions([]);
    setNumericalValue(null);
    setShowHideAnswerText(true);
  }, [question?.questionId?._id]);

  const getQuestionText = () => {
    const correctOption = question?.questionId?.options?.find(
      (opt) => opt.isCorrect
    );

    const splittedText =
      question?.questionId?.question?.text?.split("<op_")?.length - 1;
    let finalText = question?.questionId?.question?.text;
    finalText = finalText.replace(
      `<op_${index}>`,
      `<span style="text-decoration: underline; font-weight: bold;">${correctOption?.text}</span>`
    );
    // Replace text in finalText matches <op_*>
    for (let qIndex = 0; qIndex < splittedText; qIndex++) {
      const element =
        question?.questionId?.question?.text?.split("<op_")[qIndex + 1];
      const optionIndex = element?.split(">")[0];
      finalText = finalText?.replace(`<op_${optionIndex}>`, "________");
    }

    return <Latex>{finalText}</Latex>;
  };

  const getNumericalOption = () => {
    if (attemptStatus && attemptStatus.isAttempted) {
      return (
        <>
          <div class="col-lg-6 col-12 mb-3">
            <div class="card q-options border shadow-none h-100 mb-0">
              <div class="card-body pt-3 pb-3 d-flex align-items-center">
                <div class="row align-items-center flex-column">
                  <div class="col text-muted h5">Your Answer</div>
                  <div class="col">
                    {question?.status === "notVisited" ? (
                      "-"
                    ) : (
                      <>
                        {numericalValue}&nbsp;
                        {attemptStatus?.isCorrect ? (
                          <span class="badge bg-success">Correct</span>
                        ) : (
                          <span class="badge bg-danger">Incorrect</span>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12 mb-3">
            <div class="card q-options border shadow-none h-100 mb-0">
              <div class="card-body pt-3 pb-3 d-flex align-items-center">
                <div class="row align-items-center flex-column">
                  <div class="col text-muted h5">Correct Answer</div>
                  <div class="col">{question?.questionId?.correctValue}</div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        // Create an input field for numerical type question and store it in selectedOption
        <div class="col-lg-6 col-12 mb-3">
          <div class="card q-options border shadow-none h-100 mb-0">
            <div class="card-body pt-3 pb-3">
              <div class="row align-items-center flex-column">
                <div class="col-12 text-muted h5">Your Answer</div>
                <div class="col-12">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter your answer"
                    value={numericalValue}
                    onChange={(e) => setNumericalValue(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const selectOption = (option) => {
    setAttemptStatus(null);
    setSelectedOption(option);
  };

  const selectMultipleOption = (option) => {
    setAttemptStatus(null);
    if (selectedOptions?.find((opt) => opt.id === option.id)) {
      setSelectedOptions(selectedOptions.filter((opt) => opt.id !== option.id));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const getOptionStatus = (option) => {
    const selectedQuestionOption = selectedOptions?.find(
      (opt) => opt.id === option.id
    );
    if (!selectedQuestionOption) {
      if (attemptStatus?.isAttempted) {
        const originalOption = question?.questionId?.options?.find(
          (opt) => opt.id === option.id
        );
        if (originalOption) {
          if (originalOption.isCorrect) {
            return (
              <div className="mt-1">
                <span className="badge bg-success">Correct</span>
              </div>
            );
          }
        }
      }
    } else {
      if (selectedQuestionOption?.isCorrect) {
        return (
          <div className="mt-1">
            <span className="badge bg-success">You Marked | Correct</span>
          </div>
        );
      } else {
        return (
          <div className="mt-1">
            <span className="badge bg-danger">You Marked | Incorrect</span>
          </div>
        );
      }
    }
  };

  const getOption = (option, index) => {
    if (question?.questionId?.type === QUESTION_TYPE.MULTIPLE_CORRECT) {
      return (
        <div className="col-lg-6 col-12 mb-3" key={index}>
          <div
            className={`card q-options pt-3 shadow-none h-100 mb-0 cursor-pointer 
            ${
              selectedOptions?.find((opt) => opt.id === option.id)
                ? "border-active"
                : "border"
            }
          `}
            onClick={selectMultipleOption.bind(this, option)}
          >
            <div className="card-body pt-3 pb-3 d-flex align-items-center">
              <div className="row align-items-center">
                <div className="col-auto">
                  <div className="avatar avatar-question-options">
                    <span className="avatar-title rounded-circle bg-secondary">
                      {String.fromCharCode("A".charCodeAt(0) + index)}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <Latex>{option.text}</Latex>

                  {attemptStatus?.isAttempted && getOptionStatus(option)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-lg-6 col-12 mb-3" key={index}>
          <div
            className={`card q-options shadow-none h-100 mb-0 cursor-pointer ${
              selectedOption?.id === option.id ? "border-active" : "border"
            }`}
            onClick={selectOption.bind(this, option)}
          >
            <div className="card-body pt-3 pb-3 d-flex align-items-center">
              <div className="row align-items-center">
                <div className="col-auto">
                  <div className="avatar avatar-question-options">
                    <span className="avatar-title rounded-circle bg-secondary">
                      {String.fromCharCode("A".charCodeAt(0) + index)}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <Latex>{option.text}</Latex>
                  {attemptStatus?.isAttempted &&
                    selectedOption?.id === option.id && (
                      <div className="mt-1">
                        {attemptStatus?.isCorrect ? (
                          <span className="badge bg-success">
                            You Marked | Correct
                          </span>
                        ) : (
                          <span className="badge bg-danger">
                            You Marked | Incorrect
                          </span>
                        )}
                      </div>
                    )}
                  {attemptStatus?.isAttempted && (
                    <>
                      {option.isCorrect && selectedOption?.id !== option.id && (
                        <div className="mt-1">
                          <span className="badge bg-success">Correct</span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const checkAnswer = () => {
    if (
      question?.questionId?.type === QUESTION_TYPE.SINGLE_CORRECT ||
      question?.questionId?.type === QUESTION_TYPE.FILL_THE_BLANKS
    ) {
      if (selectedOption?.isCorrect) {
        setAttemptStatus({
          isCorrect: true,
          isAttempted: true
        });
      } else {
        setAttemptStatus({
          isCorrect: false,
          isAttempted: true
        });
      }
    } else if (question?.questionId?.type === QUESTION_TYPE.NUMERICAL) {
      const { numericalLowerLimit, numericalUpperLimit, correctValue } =
        question?.questionId;
      // if selectedOption is in range of numericalLowerLimit and numericalUpperLimit
      if (
        (numericalLowerLimit || numericalLowerLimit === 0) &&
        (numericalUpperLimit || numericalUpperLimit === 0)
      ) {
        if (
          numericalValue >= numericalLowerLimit &&
          numericalValue <= numericalUpperLimit
        ) {
          setAttemptStatus({ isCorrect: true, isAttempted: true });
        } else {
          setAttemptStatus({ isCorrect: false, isAttempted: true });
        }
      } else {
        if (numericalValue === correctValue) {
          setAttemptStatus({ isCorrect: true, isAttempted: true });
        } else {
          setAttemptStatus({ isCorrect: false, isAttempted: true });
        }
      }
    } else if (question?.questionId?.type === QUESTION_TYPE.MULTIPLE_CORRECT) {
      let isCorrect = true;
      for (let i = 0; i < selectedOptions.length; i++) {
        if (!selectedOptions[i].isCorrect) {
          isCorrect = false;
          break;
        }
      }
      setAttemptStatus({ isCorrect: isCorrect, isAttempted: true });
    }
  };

  const toggleHideAnswerModal = () => {
    setShowHideAnswerModal(!showHideAnswerModal);
  };
  const toggleHideAnswerText = () => setShowHideAnswerText(!showHideAnswerText);

  const getVideoCipherOtp = async () => {
    const videoId = question?.questionId?.videoSolution;
    if(!videoId || isNumeric(videoId)) return;
    try {
      const res = await axios.get(`${API_URL}/api/video/${videoId}/getotp`);
      return {
        otp: res?.data?.data?.otp,
        playBackInfo: res?.data?.data?.playbackInfo
      };
    } catch (err) {
      console.error(`Error fetching OTP and playback info for videoId ${videoId}. Error ${err.message}`);
    }
  }

  const handleShowVdoCipherVideoSolution = async () => {
    const videoOtp = await getVideoCipherOtp();
    if(!videoOtp) return;
    const { otp, playBackInfo } = videoOtp;
    setOtp(otp);
    setPlaybackInfo(playBackInfo);
    setModalOpen(true);
  }

  return (
    <div className="">
      {/* Question Body  */}
      <div className="">
        <div className="row mb-4">
          <div className="col-12">
            {question?.questionId?.type === "fillTheBlanks" ? (
              <p>{getQuestionText()}</p>
            ) : (
              <Latex>{question?.questionId?.question?.text}</Latex>
            )}
          </div>
        </div>
        <div className="row">
          {question?.questionId?.type === "numerical" ? (
            <>{getNumericalOption()}</>
          ) : (
            <>
              {question?.questionId?.options.map((option, index) => {
                return <>{getOption(option, index)}</>;
              })}
            </>
          )}
        </div>

        {showHideAnswerText &&
          viewingFrom &&
          viewingFrom === "errors" &&
          attemptStatus &&
          attemptStatus?.isCorrect && (
            <div className="d-flex align-items-center my-3 gap-3">
              <p
                className="align-center gap-2 d-flex mb-0 "
                style={{
                  color: "var(--bs-pink)"
                }}
              >
                <i className="fe fe-info mr-2"></i>
                Do you want to remove this question from errors?
                {/* [Yes] [No] */}
              </p>
              <button
                className="btn btn-sm btn-danger"
                onClick={toggleHideAnswerModal}
              >
                Yes
              </button>
              <button
                className="btn btn-sm btn-primary"
                onClick={toggleHideAnswerText}
              >
                No
              </button>
            </div>
          )}
        {!attemptStatus && (
          <div className="text-center my-4 d-flex justify-content-center gap-3">
            {/* Create check answer button */}
            <button className="btn btn-primary " onClick={checkAnswer}>
              Check Answer
            </button>
          </div>
        )}
      </div>

      {/* Solution */}
      {attemptStatus?.isAttempted && (
        <div className=" py-4">
          <div className="row mb-3 align-items-center">
            <div className="col">
              <div className="d-flex justify-content-between">
                <h5 className="mb-0 text-muted text-uppercase">Solution</h5>
                {question?.questionId?.videoSolution && (
                  <div className="col-auto">
                    {!isNumeric((question.questionId.videoSolution)) ? (
                      // If videoSolution is not a valid number
                      <>
                        <div>
                          <a
                            className="btn btn-sm btn-danger fancybox"
                            onClick={handleShowVdoCipherVideoSolution}
                          >
                            <i className="fe fe-play me-1"></i>
                            Video Solution
                          </a>

                          <VideoModal
                            title="Video Solution"
                            show={modalOpen}
                            onClose={() => setModalOpen(false)}
                          >
                            <iframe
                              src={`https://player.vdocipher.com/v2/?otp=${otp}&playbackInfo=${playbackInfo}`}
                              allow="encrypted-media"
                              loading="lazy"
                              allowFullScreen
                              style={{ border: "0", width: "100%", height: "100%", display: "block" }}
                            ></iframe>
                          </VideoModal>
                        </div>
                      </>

                    ) : (
                      // If videoSolution is a valid number
                      <Fancybox>
                        <a
                          href={`https://player.vimeo.com/video/${question?.questionId?.videoSolution}?title=0&amp;byline=0&amp;portrait=0`}
                          data-fancybox
                          data-type="iframe"
                          className="btn btn-sm btn-danger fancybox"
                        >
                          <i className="fe fe-play me-1"></i>
                          Video Solution
                        </a>
                      </Fancybox>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Latex>{question?.questionId?.solution?.text}</Latex>
            </div>
          </div>
        </div>
      )}

      <Modal
        show={showHideAnswerModal}
        onHide={toggleHideAnswerModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4 className="text-center mb-4">Are you sure?</h4>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <button
              className="btn btn-primary px-4"
              onClick={toggleHideAnswerModal}
            >
              No
            </button>
            <button
              className="btn btn-danger px-4"
              onClick={()=>{setShowHideAnswerModal(!showHideAnswerModal); hideAnswerFromErrors(); }}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Question;
